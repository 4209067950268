
           settings {
             position: static;
             display: block;
             flex: none;
             width: auto;
           }
     
           @media (screen and min-width: 1280px) {
             empty-layout + settings .settings-cog {
               right: 0 !important;
             }
           }
         