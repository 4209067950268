
           .fuse-confirmation-dialog-panel {
             padding: 10px !important;
             @screen md {
               @apply w-128;
             }
     
             .mat-mdc-dialog-container {
               .mat-mdc-dialog-surface {
                 padding: 0 !important;
               }
             }
           }
         